import request from "tools/request.js"

class user_info_get {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;


		let m_user_id = that.parent.m_user_id; 

		let session = localStorage.getItem("session");

		let params = {
			m_user_id: m_user_id, 
			session: session
		}

		request.post('/huiyuan/chongzhi_get', params).then(res => {
			
			if(res.data.state == 200){ 
				that.parent.m_user_nickname = res.data.m_user_nickname;
				that.parent.m_old_nickname = res.data.m_user_nickname;
				that.parent.m_user_biaoqian = res.data.m_user_biaoqian;
				that.parent.m_old_biaoqian = res.data.m_user_biaoqian;
				console.log("cccd");
				console.log(that.parent.m_old_biaoqian); 
			}else{  
				that.parent.m_user_nickname = ""; 
			}  
			// that.parent.m_img = that.parent.m_shoufei_info["shan_headimg"]
			// that.parent.m_img2 = that.parent.m_shoufei_info["shan_headimg"]
			// that.parent.m_shoufei_info.shan_passwd = "";
		})

	}
}

export default user_info_get