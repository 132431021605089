<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>


			<el-row>
				<el-col class="yu_input_label" :span="7">
					用户id:
				</el-col>
				<el-col :span="17">
					<el-input v-model="m_user_id"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="7">
					用户昵称:
				</el-col>
				<el-col :span="17">
					<el-input v-model="m_user_nickname"></el-input>
				</el-col>
			</el-row>
			<br />
			
  
			<el-row>
				<el-col class="yu_input_label" :span="7">
					充值金额:
				</el-col>
				<el-col :span="17">
					<el-input v-model="m_chongzhi_jine"></el-input>
				</el-col>
			</el-row>
			<br />
 
			<el-row class="yu_content_right">
				<el-button @click="chongzhi_set" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import chongzhi_get  from "./expand/chongzhi_get.js";
	import chongzhi_post from "./expand/chongzhi_post.js";

	export default {

		props: ["m_user_id", "parent"],
		data() {
			return {
				m_xingqi_no: 1, 
				m_user_nickname : "",
				m_chongzhi_jine:0 
			}
		},
		created() {
			const that = this;
			that.chongzhi_get = new chongzhi_get(that);
			that.chongzhi_get.m_main();
		},
		methods: {
			chongzhi_set() {
				const that = this;
				that.chongzhi_post = new chongzhi_post(that);
				that.chongzhi_post.m_main();
			} 
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>