import request from "tools/request.js"

//建筑分类 添加建筑分类
class user_info_post {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;


		let m_user_id = that.parent.m_user_id; 
		let m_user_biaoqian = that.parent.m_user_biaoqian; 
		let m_old_id = that.parent.m_old_id; 
		let m_old_biaoqian = that.parent.m_old_biaoqian; 
		 
		
		let session = localStorage.getItem("session");

		let params = {
			m_old_id :m_old_id,
			m_user_id: m_user_id,
			m_user_biaoqian: m_user_biaoqian,
			m_old_biaoqian : m_old_biaoqian, 
			session: session
		}

		request.post('/huiyuan/user_info_post', params).then(res => {
			if (res.data.state == 200) {
				this.parent.$notify({
					title: '成功',
					message: res.data.message,
					type: 'success'
				}); 
			} else {
				this.parent.$notify({
					title: '失败',
					message: res.data.message,
					type: 'error'
				});
			}
			that.parent.parent.drawer_2 = false;
			that.parent.parent.huiyuan_get.m_main();
		 
		})

	}
}

export default user_info_post