import request from "tools/request.js"

//建筑分类 添加建筑分类
class chongzhi_post {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;


		let m_user_id = that.parent.m_user_id; 
		let m_chongzhi_jine = that.parent.m_chongzhi_jine; 
		
		
		let session = localStorage.getItem("session");

		let params = {
			m_user_id: m_user_id,
			m_chongzhi_jine: m_chongzhi_jine,
			session: session
		}

		request.post('/huiyuan/chongzhi_post', params).then(res => {
			if (res.data.state == 200) {
				this.parent.$notify({
					title: '成功',
					message: res.data.message,
					type: 'success'
				});
			} else {
				this.parent.$notify({
					title: '失败',
					message: res.data.message,
					type: 'error'
				});
			}
			that.parent.parent.drawer_1 = false;
			that.parent.parent.huiyuan_get.m_main();
		 
		})

	}
}

export default chongzhi_post