<template>
	<div>
		<layout>
			<br />
			<el-row :gutter="20">

				<el-col :span="8">
					<el-col :span="8"><label class="yu_input_label">会员id:</label>
					</el-col>
					<el-col :span="16">
						<el-input v-model="m_huiyuan_id" @input="m_input_change" placeholder="请输入内容" clearable>
						</el-input>
					</el-col>
				</el-col>
				<!-- 	<el-col :span="16">
					<el-button class="yu_right_1" @click="drawer_1 = true" plain>
						<i class="el-icon-plus"></i>添加商家
					</el-button>
				</el-col> -->
			</el-row>
			<br />


			<br />
			<el-table :data="m_huiyuan_list" style="width: 100%">
				<el-table-column prop="user_id" label="id">
				</el-table-column>
				<el-table-column prop="user_nickname" label="昵称"  >
				</el-table-column>
				<el-table-column prop="user_yue" label="余额" > 
				</el-table-column> 
				<el-table-column prop="user_leiji" label="累计充值" > 
				</el-table-column>
				<!-- <el-table-column prop="chdi_state" label="场地状态">
					<template slot-scope="scope">
						<el-button size="mini" type="default" @click="f_shoufei_set(scope.row.chdi_id)">
							使用中
						</el-button>
						<el-button size="mini" type="default" @click="f_xinxi_set(scope.row.chdi_id)">
							空闲
						</el-button>
					</template>
				</el-table-column> -->
				<el-table-column label="操作" width="220" align="right">
					<template slot-scope="scope">
						<el-button size="mini" type="default" @click="f_chongzhi_set(scope.row.user_id)">
							充值 
						</el-button> 
						<el-button size="mini" type="default" @click="f_user_id_set(scope.row.user_id)">
							设置信息
						</el-button> 
					</template>
				</el-table-column>
			</el-table>

			<br />
			<br />

			<div class="yu_content_right">
				<el-pagination background @current-change="page_current_change" layout="prev, pager, next"
					:page-size="page_size" :total="max_page">
				</el-pagination>
			</div>
			<br />
		</layout>
	 
		 
		 
		 <el-drawer title="会员充值" :visible.sync="drawer_1" size="600px">
		 	<chongzhi_set :key="m_user_id" :m_user_id="m_user_id" :parent="me"></chongzhi_set>
		 </el-drawer>
		 
		 <el-drawer title="会员信息设置" :visible.sync="drawer_2" size="600px">
		 	<user_id_set :key="m_user_id" :m_user_id="m_user_id" :parent="me"></user_id_set>
		 </el-drawer>
	</div>
</template>

<script>
	import layout from 'views/layout.vue'
 
 
	import chongzhi_set from './components/chongzhi_set/chongzhi_set'
	import user_id_set  from './components/user_id_set/user_id_set'
	import huiyuan_get  from "./expand/huiyuan_get.js"


	export default {
		components: {
			layout,  
			chongzhi_set,
			user_id_set
		},
		data() {
			return {
				me: this,
				m_user_id: "",
				m_huiyuan_id: "",
				m_huiyuan_list: [],
				drawer_1: false,
				drawer_2: false,
				drawer_3: false,
				checkedNames: [],
				page: 1,
				page_size: 10,
				max_page: 10, 
			}
		},
		created() {
			const that = this;

			that.huiyuan_get = new huiyuan_get(that);
			that.huiyuan_get.m_main();

		},
		methods: {

			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			page_current_change(val) {
				//翻页
				const that = this;

				that.page = val;
				that.huiyuan_get.m_main();

				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			m_input_change(val) {
				const that = this;
				that.m_huiyuan_id = val;
				that.huiyuan_get.m_main();
			},
			f_chongzhi_set(user_id) {
				//点击了充值
				const that = this; 
				
				console.log("123123");
				that.m_user_id = user_id;
				that.drawer_1 = true;
			},
			f_user_id_set(user_id) {
				//点击了会员设置
				const that = this; 
				 
				that.m_user_id = user_id;
				that.drawer_2 = true;
			},
			f_yuding_info(chdi_id) {
				//点击了预定信息
				const that = this; 
				 
				that.m_chdi_id = chdi_id;
				that.drawer_2 = true;
			},
			f_go_shangpin(shan_id) {
				const that = this;
				that.$router.push({
					path: '/shangpin',
					query: {
						"huiyuan_id": shan_id
					}
				});
			},
			f_go_manghe(shan_id) {
				const that = this;
				that.$router.push({
					path: '/manghe',
					query: {
						"huiyuan_id": shan_id
					}
				});
			},
			f_go_yiyuanchou(shan_id) {
				const that = this;
				that.$router.push({
					path: '/yiyuanchou',
					query: {
						"huiyuan_id": shan_id
					}
				});
			}
		}
	}
</script>